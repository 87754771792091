import { MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'

export type TLocaleId = 'en' | 'de' | 'it' | 'fr'

type TLocale = {
  id: TLocaleId
  idLocale: number
  localeName: string
  message: MessageDescriptor
}

type TLocaleMap = Record<TLocaleId, TLocale>

const LOCALE_MAP: TLocaleMap = {
  en: {
    id: 'en',
    idLocale: 66,
    localeName: 'en_US',
    message: defineMessage({ message: 'English' }),
  },
  de: {
    id: 'de',
    idLocale: 46,
    localeName: 'de_DE',
    message: defineMessage({ message: 'Deutsch' }),
  },
  it: {
    id: 'it',
    idLocale: 121,
    localeName: 'it_IT',
    message: defineMessage({ message: 'Italiano' }),
  },
  fr: {
    id: 'fr',
    idLocale: 100,
    localeName: 'fr_FR',
    message: defineMessage({ message: 'Français' }),
  },
}

export const getLocaleIds = () => Object.values(LOCALE_MAP).map(({ id }) => id)
export const getLocales = () => Object.values(LOCALE_MAP)
export const getLocale = (localeId: TLocaleId) => {
  return LOCALE_MAP[localeId]
}
export const getLDICById = (idLocale: number) =>
  Object.values(LOCALE_MAP).find(
    ({ idLocale: _idLocale }) => idLocale === _idLocale
  )?.id
