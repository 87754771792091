import React from 'react'

import { i18n } from '@lingui/core'

type CurrencyProps = {
  cents: number
  currencyIsoCode?: string
}

const Currency: React.FC<CurrencyProps> = ({ cents, currencyIsoCode }) => (
  <span className="tabular-nums">
    <span>
      {currencyIsoCode
        ? i18n.number(cents / 100, {
            style: 'currency',
            currency: currencyIsoCode,
          })
        : `${i18n.number(cents / 100, {
            style: 'decimal',
            minimumFractionDigits: 2,
          })} ?`}
    </span>
  </span>
)

export default Currency
