import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from 'react-query'

import {
  closestTo,
  isEqual,
  isFuture,
  isWithinInterval,
  parseISO,
} from 'date-fns'
import memoize from 'lodash.memoize'

import { getAvailabilityPeriods } from 'src/api'

export const AVAILABILITY_STATES = {
  available: 'available',
  later: 'later',
  unavailable: 'unavailable',
}

const getPercentage = (quantity?: number, base = 100 / 300) =>
  quantity && quantity > 0 ? quantity * base : 0

const closestAvailabilityResolver = (
  availabilities: TAvailability[],
  date?: Date
) =>
  `${date ? `${date}: ` : ''}${
    availabilities?.map(({ id }) => id).join(',') || ''
  }`

interface TAvailability {
  start: string
  end: string
  id: string
  quantity: number
  earliestDeliveryDate: string
  originalStart: string
}

export const closestAvailability = memoize(
  (availabilities: TAvailability[], date?: Date) => {
    const today = new Date()
    const compareDate = date || today
    let availability = null
    // there are no availabilities, bail out
    if (!availabilities || availabilities.length === 0) {
      return availability
    }

    // try to find an availability where our compareDate falls inside its start/end range
    availability = availabilities?.find(({ start, end }: TAvailability) =>
      isWithinInterval(compareDate, {
        start: parseISO(start),
        end: parseISO(end),
      })
    )

    // we didn't find one, so now we're looking for an availability
    // where the start date is closest to our compareDate
    if (!availability) {
      const startDates = availabilities.map(({ start }) => parseISO(start))
      const closest = closestTo(compareDate, startDates) as Date
      availability = availabilities.find(({ start }) =>
        isEqual(parseISO(start), closest)
      )
    }
    return availability || null
  },
  closestAvailabilityResolver
)

const getAvailabilityState = (start?: string, end?: string, quantity = 0) => {
  const today = new Date()

  if (!start || !end || !(quantity > 0)) {
    return AVAILABILITY_STATES.unavailable
  }

  if (
    isWithinInterval(today, {
      start: parseISO(start),
      end: parseISO(end),
    })
  ) {
    return AVAILABILITY_STATES.available
  }

  if (isFuture(parseISO(start))) {
    return AVAILABILITY_STATES.later
  }

  return AVAILABILITY_STATES.unavailable
}

export const makeAvailability = memoize(
  (availabilities) => {
    const { id, start, end, quantity, earliestDeliveryDate, originalStart } =
      closestAvailability(availabilities) || {}
    const state = getAvailabilityState(start, end, quantity)
    const percentage = getPercentage(quantity)

    return {
      id,
      date: start ? parseISO(start) : undefined,
      quantity,
      state,
      percentage,
      availabilities: availabilities.slice(),
      earliestDeliveryDate: earliestDeliveryDate
        ? parseISO(earliestDeliveryDate)
        : undefined,
      originalStartDate: originalStart ? parseISO(originalStart) : null,
    }
  },
  (availabilities) => {
    const { id } = closestAvailability(availabilities) || {}
    return id
  }
)

const availabilitiesQueryFn = (productIds: string, channel?: string) => {
  if (!productIds || !productIds?.length) {
    return Promise.resolve({})
  }

  return getAvailabilityPeriods(productIds, channel)
}

type HookProps = {
  companyUserReference?: string
  channel?: string
}

interface UsePaginatedProductsFn<HookProps, UseInfiniteQueryOptions> {
  //TODO: Add ESLint Typescript Rules
  // eslint-disable-next-line no-unused-vars
  (args: HookProps, options: UseInfiniteQueryOptions): UseInfiniteQueryResult
}

const usePaginatedAvailabilities: UsePaginatedProductsFn<
  HookProps,
  UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, string[]>
> = ({ companyUserReference, channel } = {}, options) =>
  useInfiniteQuery(
    [
      'availability-periods-paginated',
      ...(companyUserReference ? [companyUserReference] : []),
      ...(channel ? [channel] : []),
    ],
    ({ pageParam: productIds }) => availabilitiesQueryFn(productIds, channel),
    {
      staleTime: 5 * (60 * 1000), //5min
      cacheTime: 15 * (60 * 1000), //10min
      ...options,
    }
  )

export default usePaginatedAvailabilities
