import { privateClient } from 'src/api/api-client'
import { transformProducts } from 'src/api/products/utils'

export const getProducts = async ({
  offset,
  limit,
  sort = '',
  searchTerm = '',
  filters = {},
  price = { min: '', max: '' },
  onePerGroupHash = false,
  groupHash = [],
}: {
  offset: number
  limit: number
  sort?: string
  searchTerm?: string
  filters?: Record<string, string | string[]>
  price?: { min: string; max: string }
  onePerGroupHash?: boolean
  groupHash?: string[]
}) => {
  const searchParams = new URLSearchParams({
    sort,
    q: searchTerm,
    'page[offset]': String(offset),
    'page[limit]': String(limit),
    'price[min]': price.min,
    'price[max]': price.max,
    'one-per-group-hash': String(onePerGroupHash),
  })

  Object.entries(filters).forEach(
    ([filter, values]: [string, string | string[]]) =>
      typeof values === 'string'
        ? searchParams.append(`${filter}`, values)
        : values.forEach((value: string) =>
          searchParams.append(`${filter}[]`, value)
        )
  )
  groupHash.map((item) => searchParams.append(`group-hash[]`, item))

  const response: any = await privateClient('catalog-search', {
    searchParams,
  }).json()

  response.data[0].attributes.abstractProducts = transformProducts(
    response.data[0].attributes.abstractProducts
  )

  return response.data[0].attributes
}
