const PERMISSIONS = {
  createCompanyUser: {
    action: 'create',
    subject: 'companyUsers',
  },
  updateCompanyUser: {
    action: 'update',
    subject: 'companyUsers',
  },
  deleteCompanyUser: {
    action: 'delete',
    subject: 'companyUsers',
  },
  createShippingAddress: {
    action: 'create',
    subject: 'addresses',
  },
  updateShippingAddress: {
    action: 'update',
    subject: 'addresses',
  },
  deleteShippingAddress: {
    action: 'delete',
    subject: 'addresses',
  },
  deleteCompany: {
    action: 'delete',
    subject: 'company',
  },
  seeAvailability: {
    action: 'see',
    subject: 'availability',
  },
  updateDistributionProductLists: {
    action: 'update',
    subject: 'distributionProductLists',
  },
  claimCart: {
    action: 'claim',
    subject: 'cart',
  },
  uploadCart: {
    action: 'upload',
    subject: 'cart',
  },
  uploadCartAsRetailer: {
    action: 'uploadAsRetailer',
    subject: 'cart',
  },
  cloneCart: {
    action: 'clone',
    subject: 'cart',
  },
  exportCart: {
    action: 'export',
    subject: 'cart',
  },
  mergeDeliveryDates: {
    action: 'merge',
    subject: 'deliveryDates',
  },
  updateCompanyUserWithRole: (role: string) => ({
    action: 'update',
    subject: 'companyUsers',
    conditions: { 'companyRoles.0.name': role },
  }),
  deleteCompanyUserWithRole: (role: string) => ({
    action: 'delete',
    subject: 'companyUsers',
    conditions: { 'companyRoles.0.name': role },
  }),
  seeGlobalSortOrder: {
    action: 'see',
    subject: 'globalSortOrder',
  },
  seeBetaMode: {
    action: 'see',
    subject: 'betaMode',
  },
  postDefaultCompanyUser: {
    action: 'set',
    subject: 'defaultCompanyUser',
  },
  seeOrderBudgetOnCart: {
    action: 'see',
    subject: 'orderBudget',
  },
  updateOrderBudget: {
    action: 'update',
    subject: 'orderBudget',
  },
  selectCompanyForAvailabilitySearch: {
    action: 'select',
    subject: 'company',
  },
  seeAllMedia: {
    action: 'see',
    subject: 'allMedia',
  },
  seeAllMasterData: {
    action: 'see',
    subject: 'allMasterData',
  },
  persistTradeFair: {
    action: 'persist',
    subject: 'tradeFair',
  },
  preventCustomerOrderConfirmationMail: {
    action: 'prevent',
    subject: 'customerOrderConfirmationMail',
  },
}

export default PERMISSIONS
